<template>
<v-card 
  :class="{
    'mt-3': $vuetify.breakpoint.xs,
    'ml-3 mt-3 pt-2 pb-1 mb-2' : $vuetify.breakpoint.mdAndUp
  }">
  <div style="width=375px; padding: 8px">
    <chart-bar :chartData="chartData" />
  </div> 
</v-card> 
</template>

<script>
import ChartBar from "../Donut/index";
import { mapGetters } from 'vuex';

export default {
    components: { ChartBar },
     data() {
      return {
        chartData: {},
        labelsEn: ["Total", "Present", "Absent", "Ill", "Unexcused", "Logged out"],
        labelsDe: ["Gesamt","Anwesend","Abwesend", "Krank", "Unentschuldigt", "Gegangen"]
      };
    },
    watch: {
      presentStudents: function() {
        this.generateData();
      },
      '$i18n.locale': function() {
        this.generateData();
      }
    },
    computed: {
       ...mapGetters({
            students: 'dashboardOnline/getStudents',
            presentStudents : 'dashboardOnline/presentStudents',
            absentStudents: 'dashboardOnline/absentStudents',
            illStudents: 'dashboardOnline/illStudents',
            unExcusedStudents: 'dashboardOnline/unExcusedStudents',
            loggedOutStudents: 'dashboardOnline/loggedOutStudents',
            dayInfo: 'dashboardOnline/dayInfo',
        })
    },
    methods: {
    generateData() {
      let newArray = [
         this.students.length, 
         this.presentStudents.length,
         this.absentStudents.length,
         this.illStudents.length,
         this.unExcusedStudents.length,
         this.loggedOutStudents.length 
      ];
      this.chartData = {
        labels: this.$i18n.locale === 'en' ? this.labelsEn : this.labelsDe,
        datasets: [
          {
            label: "Data",
            backgroundColor: ["#6155a6", "#4caf50", "#f04535", "#fb8c00", "#1976d2", "black"],
            data: newArray,
            // borderColor: 'lightgray'
          }
        ]
      };
    }
  },
  mounted() {
    this.generateData();
  }
}
</script>


